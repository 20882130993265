angular
    .module('annexaApp')
    .component('annexaBoxObjectThirdsConsent',{
    	templateUrl: './components/thirds/annexa-box-object-thirds-consent/annexa-box-object-thirds-consent.html',
    	controller:['$rootScope', '$filter', 'Language', 'GlobalDataFactory', 'HelperService', 'CommonService', '$scope', '$state', 'AnnexaFormlyFactory', 'TerritoryFactory', 'AnnexaPermissionsFactory', 'globalModals', 'TramNewFactory', 'DialogsFactory', 'ThirdFactory', '$http', 'RestService', 'TableFilter','DTColumnBuilder', 'AnnexaModalFactory', 'ErrorFactory',
    		function($rootScope, $filter, Language, GlobalDataFactory, HelperService, CommonService, $scope, $state, AnnexaFormlyFactory, TerritoryFactory, AnnexaPermissionsFactory, globalModals, TramNewFactory, DialogsFactory, ThirdFactory, $http, RestService, TableFilter, DTColumnBuilder, AnnexaModalFactory, ErrorFactory) {
    		var vm = this;
    		vm.languageColumn = undefined;
			vm.consentBox = undefined;
			vm.deleteConsent = function(consentId){
				var titleDeleteMessage = '';
	        	var confirmDeleteMessage = '';
	        	
	        	titleDeleteMessage = 'global.literals.delete';
	        	confirmDeleteMessage = 'global.literals.deleteConsentBox';
	        	
	        	DialogsFactory.confirm(titleDeleteMessage, confirmDeleteMessage)
	                .then(function (data) {
	                	ThirdFactory.deleteThirdConsent(consentId).then(function(data) {
	                  	  if(vm.consentTableDefinition && vm.consentTableDefinition.reloadInternalData) {
	      						vm.consentTableDefinition.reloadInternalData(true, true);	
	      						ThirdFactory.calculateCounters('thirdConsents');
	      					}
	                    }).catch(function(error) {
	                    	if(error){
	                    		vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
				      		}else{
				      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
				      		}
	                    });
	                }).catch(function (data) {
	                    //Empty
	            });
				
			};
			vm.editConsent = function(consentId){
				ThirdFactory.getRelatedThirdAddresses(vm.third.id).then(function(data){
					var editedConsent = angular.copy($scope.tableObjects[consentId]);
	            	var modal = angular.copy(globalModals.consentsEdit);
	            	modal.annexaFormly.model = {};
	                modal.annexaFormly.model.modal_body = editedConsent;
	                modal.annexaFormly.options = { watchAllExpressions: true };
	                modal.annexaFormly.options.formState = {readOnly: false, data: ((data)?JSOG.decode(data):[])};
	                modal.extra = this;
	                var completedEdit = function(modal){
	                	var thirdConsent = {
	                			id: consentId,
	                			third: {id: vm.third.id},
	                			thirdAddress:{id:modal.annexaFormly.model.modal_body.thirdAddress.id}
	                	};
	                    ThirdFactory.updateThirdConsent(thirdConsent)
	                        .then(function(data) {
	                        	if(vm.consentTableDefinition && vm.consentTableDefinition.reloadInternalData) {
		      						vm.consentTableDefinition.reloadInternalData(true, true);	
		      						ThirdFactory.calculateCounters('thirdConsents');
		      					}
	                            modal.close();
	                        }).catch(function(error) {
	                        	if(error){
	                        		vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
	    			      		}else{
	    			      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
	    			      		}
	                    });
	            	};
	                AnnexaFormlyFactory.showModal("modalEditConsent", modal, completedEdit, false);
				}).catch(function(error) {
					if(error){
		      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
		      		}else{
		      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		      		}
				});
			};
			
			vm.addThirdConsent = function(){
				ThirdFactory.getRelatedThirdAddresses(vm.third.id).then(function(data){
					var modal = angular.copy(globalModals.consentsAdd);
					var completeAddConsent = function () {
						var proccesResult = function (object) {
							  return object.dossierNumber + " (" + object.subject + ")";
						  };
						var model = this.annexaFormly.model.modal_body;
						var consent = { consentType: model.consentType.id, title: '', thirdAddress: model.thirdAddress };
						
						switch(model.consentType.id) {
						    case 'FAMILY_PROCEDURE':
							    consent.familyProcedure = { id: model.familyProcedure.$selected.id };
							    consent.title = model.familyProcedure.$selected.title;
							    break;
							case 'PROCEDURE':
							    consent.procedure = model.procedure;
							    consent.title = model.procedure[Language.getActiveColumn()];
							    break;
							case 'DOSSIER':
						        consent.dossier = model.dossier.object;
						        consent.title = proccesResult(consent.dossier);
						        break;
						    }
						    
					    var consents = {
					    		third: {id:vm.third.id},
								consentType: consent.consentType,
								deleted: false,
								dossier: consent.dossier ? consent.dossier : undefined,
								familyProcedure: consent.familyProcedure ? consent.familyProcedure : undefined,
								procedure: consent.procedure ? consent.procedure : undefined,
								thirdAddress: { id: consent.thirdAddress.id }
					    };
						
					    ThirdFactory.addThirdConsent(consents)
					      	.then(function(data) {
					      		if(vm.consentTableDefinition && vm.consentTableDefinition.reloadInternalData) {
									vm.consentTableDefinition.reloadInternalData(true, true);
									ThirdFactory.calculateCounters('thirdConsents');
					      		}
					      		modal.close();
					      	}).catch(function(error) {
					      		if(error){
					      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
					      		}else{
					      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
					      		}
					      	});
					}
					 modal.annexaFormly.model = {};
			         modal.annexaFormly.model.modal_body = { familyProcedure: {} };
			         modal.annexaFormly.options = { watchAllExpressions: true };
			         modal.annexaFormly.options.formState = {readOnly: false, data: ((data)?JSOG.decode(data):[])};
			         modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.search = function(val) {
			              var filter = {
			                  subject: val.val,
			                  dossierNumber: val.val
			              };
	
			              var additional_filter = { };
			              return RestService.filterData('tram', 'Dossier', filter, additional_filter, 0, 10, '', 3)
			                  .then(function(data) {
			                      var response = [];
	
			                      _.forEach(data.data.content, function (value) {
			                          response.push({ id: value.id, object: JSOG.encode(value), value: proccesResult(value) });
			                      });
	
			                      response = new SortedArray(response, 'value').sort();
	
			                      return response;
			                  });
			          }
			         modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.advancedSearch = function() {
			              var expiredStates = [
			                  { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
			                  { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
			              ];

			              var tfilterDossier  = new TableFilter();
			              tfilterDossier.addElement('dossierNumber', 'text', 0, 'global.literals.code');
			              tfilterDossier.addElement('family', 'select', 1, 'global.literals.family', 2, 'FamilyProcedure', true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(true);
			              tfilterDossier.addElement('procedure', 'text', 2, 'global.literals.procedure').setFilterCallAux(true).setUserChosenFilter(true);
			              tfilterDossier.addElement('subject', 'text', 3, 'global.literals.subject').setUserChosenFilter(true);
			              tfilterDossier.addElement('thirds', 'text', 4, 'global.literals.thirdsInt').setFilterCallAux(true).setUserChosenFilter(true);
			              tfilterDossier.addElement('thirds_identification', 'text', 5, 'global.literals.thirds_identification').setFilterCallAux(true).setUserChosenFilter(true);
			              tfilterDossier.addElement('dates', 'dateRange', 8, 'global.delegate.startDate').setFilterCallAux(true).setUserChosenFilter(true);
			              tfilterDossier.addElement('creator', 'text', 9, 'global.literals.creator').setFilterCallAux(true).setUserChosenFilter(true);
			              tfilterDossier.addElement('register_entry', 'text', 10, 'global.literals.register_entry_INI').setFilterCallAux(true).setUserChosenFilter(true);
			              tfilterDossier.addElement('expired', 'select', 11, 'global.literals.expiredSate', 3, HelperService.addAllSelect(expiredStates, 'name', Language.getActiveColumn()), true, 'name', true, undefined, Language.getActiveColumn()).setFilterCallAux(true).setUserChosenFilter(true);
			              tfilterDossier.loadLocalData([],undefined, undefined, Language.getActiveColumn());

			              var tdDefDossier    = new TableData('tram', 'Dossier', './api/tram/byfilter').setSortName('remainingDays').setSort([[1, 'asc']]).setSkipFirstSearch(true);

			              var DaysColumn          = new RemaingDaysColumn($filter, 'global.literals.days', HelperService);
			              var ProcedureColumn     = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', Language.getActiveColumn());
			              var StDateColumn        = new StartDateColumn($filter, 'global.delegate.startDate');
			              var CreatorUserColumn   = new UserColumn($filter, 'global.literals.creator');
			              var RelatedColumn       = new RelatedDossiersColumn();

			              var tabledataColumnsDossier = [
			                  DTColumnBuilder.newColumn(null).renderWith(function(data, type, full, meta){
			                      return '<input type="radio" id="'+full.id+'" name ="selected" data-ng-model="radioSelected" data-ng-value="'+ full.id +'" aria-label="{{ \'global.literals.select\' | translate }}">';
			                  }).notSortable(),
			                  DTColumnBuilder.newColumn('remainingDays').withTitle(DaysColumn.getTitle()).renderWith(DaysColumn.getRender),
			                  DTColumnBuilder.newColumn('dossierNumber').withTitle($filter('translate')('global.literals.code')),
			                  DTColumnBuilder.newColumn('procedure.' + ProcedureColumn.getColumn()).withTitle(ProcedureColumn.getTitle()),
			                  DTColumnBuilder.newColumn('subject').withTitle($filter('translate')('global.literals.dossier'))
			              ];

			              var advancedModalDefinition = new BoxAdvancedSearchModalDefinition('dossierbox', 'global.literals.advancedSearchExpe', tfilterDossier, tdDefDossier, tabledataColumnsDossier, undefined, undefined, modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.advancedSearchAdd, modal).changeSize('modal-lg');
			              advancedModalDefinition.added = [];

			              AnnexaModalFactory.showBoxAdvancedFilter(advancedModalDefinition);
			          };
			          modal.extra = this;
			          AnnexaFormlyFactory.showModal("modalNewConsent", modal, completeAddConsent, false);
				}).catch(function(error) {
					if(error){
		      			vm.thirdAlerts.push({ msg: $filter('translate')(ErrorFactory.getErrorMessage('thirds', 'update', error))});
		      		}else{
		      			vm.thirdAlerts.push({msg:$filter('translate')('global.errors.unknownUpdate')});
		      		}
				});
          };
          

			var generateConsentBox= function(){
				if(!vm.consentTableDefinition){
    				vm.consentTableDefinition = {};
    			}
				var consentTypeRender = function(data, type, full, meta) {
    				var content = '<span>{{ \'global.literals.' + full.consentType + '\' | translate }}</span>';
    				
    				return content;
    			}
				
				var consentTitleRender = function(data, type, full, meta) {
					var title = "";
					if(full.familyProcedure){
						full.title = full.familyProcedure[Language.getActiveColumn()];
					}else if(full.procedure){
						full.title = full.procedure[Language.getActiveColumn()];
					}else if(full.dossier){
						var subjectDossier = ((full.dossier.subject)? " (" + full.dossier.subject + ")" : "");
						full.title = full.dossier.dossierNumber + subjectDossier;
					}
					
    				var content = '<span>'+full.title+'</span>';
    				
    				return content;
    			}
				
				var consentAddressRender = function(data, type, full, meta) {
					var thirdAddress = new ThirdAddress(undefined,Language.getActiveColumn(), undefined, $rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value);
					full.thirdAddress.name = thirdAddress.getAddressHtml(full.thirdAddress, false, true);
					
					var content = '<span>'+full.thirdAddress.name+'</span>';
					return content;
				}
				
				var actionsTitle = function () {
    	            return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
    	        }
                var actionsRender = function (data, type, full, meta) {
    				var content = '';
    				if(full.id){
    					content += '<div class="col-xs-1">';
    					content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.editConsent('+full.id+')" permission permission-only="[\'new_input_entry_register\',\'view_input_register\',\'view_all_input_register\',\'view_output_register\', \'view_all_output_register\']">';
    	                content += '<i class="fa fa-edit" title="{{ \'global.literals.edit\' | translate }}" aria-hidden="true"></i>';
    	                content += '<span class="sr-only">{{ \'global.literals.edit\' | translate}}</span>';
    	                content += '</button>';
    	                content += '</div><div class="col-xs-1">';
    					content += '<button type="button"  class="md-btn p-a-0 md-flat md-btn__star grey-pencil" ng-click="$ctrl.deleteConsent('+full.id+')" permission permission-only="[\'new_input_entry_register\',\'view_input_register\',\'view_all_input_register\',\'view_output_register\', \'view_all_output_register\']">';
    	                content += '<i class="fa fa-remove" title="{{ \'global.literals.remove\' | translate }}" aria-hidden="true"></i>';
    	                content += '<span class="sr-only">{{ \'global.literals.remove\' | translate}}</span>';
    	                content += '</button>';
    	                content += '</div>';
    				}
                    return content;
                }
                var columns = [
    				{ id: 'consentType', width: '120px', title: $filter('translate')('global.literals.type'), render: consentTypeRender},
    				{ id: 'title', width: '250px',  title: $filter('translate')('global.literals.name'), render: consentTitleRender, sortable: false},
                    { id: 'thirdAddress.name', width: '250px',  title: $filter('translate')('global.territory.list.address'), render: consentAddressRender, sortable: false},
                    { id: 'actions', columnName: 'id', width: '1px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false }
    	        ];
                
                var tableOrderProperties = {sortName: 'id', sort: [[0,'desc']]};
    	        vm.consentTableDefinition.id = 'tableTConsentThird';
    			vm.consentTableDefinition.origin = 'thirds';
    	        vm.consentTableDefinition.objectType = 'ThirdConsent';
    			vm.consentTableDefinition.objectIdToSelectOrigin ='thirds';
    			vm.consentTableDefinition.objectIdToSelectType = 'ThirdConsent';
    	        vm.consentTableDefinition.sortName = tableOrderProperties.sortName;
    	        vm.consentTableDefinition.sort = tableOrderProperties.sort;
    			vm.consentTableDefinition.columns = columns;
    	        vm.consentTableDefinition.containerScope = $scope;
    			vm.consentTableDefinition.useTableObjects = true;
    			vm.consentTableDefinition.callOrigin = 'thirds';
    			vm.consentTableDefinition.objectIdToSelect = angular.fromJson({third:{id:vm.third.id}});
    			vm.consentTableDefinition.objectIdToAdded = vm.third.id;
    			
    			vm.consentBox = {
    			    boxTitle: 'global.literals.consents',
    			    permissions: {new: ['edit_third']},
    				tableDefinition: vm.consentTableDefinition,
                    isEdit: true,
                    search: {},
                    new:{newFunc:vm.addThirdConsent},
                    config: {third: vm.third},
                    origin: 'thirds',
    				alerts: vm.thirdAlerts,
    				legend: undefined,
    				closeBox: vm.closedBox,
    				numberBox: ThirdFactory.numberConsentBox
                }
			};
			
			this.$onInit = function() {
				vm.languageColumn = Language.getActiveColumn();
				vm.consentBox = {};
	    		try{
					generateConsentBox();	
					$scope.$on('annexaBoxThirdConsentTable', function(event, args) {
			            if(vm.consentTableDefinition && vm.consentTableDefinition.reloadInternalData) {
							vm.consentTableDefinition.reloadInternalData(true, true);
							ThirdFactory.calculateCounters('thirdConsents');
						}
						//vm.dossierInfoComponent.dossierTabsComponent.dossierComponent.calculateCounters('dossierRegisterOutputs');
			        });
				}catch(error){
					 console.error(error);
				}
			}
			
    	}],
        bindings: {
      	  third:'=',
      	  closedBox:'=',
      	  thirdAlerts:'='
        }
      })
      .component('annexaBoxConsentPaginated', {
        templateUrl: './components/thirds/annexa-box-object-thirds-consent/annexa-box-consent-paginated.html',
        controller:['AnnexaObjectBoxFactory', 'Language', '$rootScope', 'RestService', 'RegFactory', 'GlobalDataFactory', '$filter', 'AnnexaFormlyFactory', '$scope', '$timeout', '$q','TramNewFactory', function (AnnexaObjectBoxFactory, Language, $rootScope, RestService, RegFactory, GlobalDataFactory, $filter, AnnexaFormlyFactory, $scope, $timeout, $q, TramNewFactory) {
            var vm = this;
            this.$onInit = function () {
				if(vm.box && vm.box.tableDefinition){
					vm.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
	                
	            	if(!vm.boxTitle) {
	                    vm.boxTitle = 'global.literals.consents';
	            	}
				}
            }
        }],
        bindings: {
            box: '='
        }
    });
